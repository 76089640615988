body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input {
  border-radius: 10px;
  border: 1px solid rgba(109, 109, 109, 0.286);
  padding: 20px; 
  width: 100%;
  height: 15px;    
}

.label {
  font-weight: bold;
  font-size: 14px;
}

.selection {
  border-radius: 10px;
  border: 1px solid rgba(109, 109, 109, 0.286);
  padding: 20px; 
  height: 15px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #404040;
  font-size: 14px;
}

.selection-selected {
  border-radius: 10px;
  border: 1px solid #EA2127;
  padding: 20px; 
  height: 15px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-color: #EA2127;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 14px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.selection:hover {
  border: 1px solid hsl(0, 100%, 86%);
  background-color: hsl(0, 100%, 95%);
  color: #EA2127;
  font-size: 14px;
}

.submit-button {
  font-weight: bold;
  font-size: 16;
  border: 1px solid #f1f1f1f1;
  background-color: black;
  border-radius: 10px;
  color: white;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.submit-button:hover {
  font-weight: bold;
  font-size: 16;
  border: 1px solid #f1f1f1f1;
  border-radius: 10px;
  background-color: hsl(0, 100%, 95%);
  color: #EA2127;
}

.box-shadow {
  box-shadow: 0 3px 12px rgba(174, 174, 174, 0.119);
}

.color-selection {
  border-radius: 10px;
  border: 1px solid rgba(109, 109, 109, 0.286);
  padding: 20px; 
  height: 15px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #404040;
  font-size: 14px;
}

.color-selection-selected {
  border-radius: 10px;
  border: 1px solid #EA2127;
  padding: 20px; 
  height: 15px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-color: #EA2127;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 14px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.color-selection:hover {
  border: 1px solid hsl(0, 100%, 86%);
  background-color: hsl(0, 100%, 95%);
  color: #EA2127;
  font-size: 14px;
}

